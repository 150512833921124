"use client"

import { forwardRef, useState, type Ref } from "react"
import clsx from "clsx"
import { AnimatePresence, m } from "framer-motion"

import type { TPanelMaterials } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/_data/serializer"
import { PanelMaterialsComponent } from "~/components/ui/Collection/CollectionDefault/components/PanelMaterials/components"
import { Link, type AbstractLinkProps } from "~/components/ui/Link"
import { usePanel } from "~/managers/PanelManager"
import { fromBezierToArray } from "~/utils/from-bezier-to-array"

import { sprinkles, type Sprinkles } from "~/styles/sprinkles.css"
import { easings } from "~/styles/variables/easings"

import * as css from "./styles.css"

export type InlineCtaProps = AbstractLinkProps &
  css.InlineCtaVariants & {
    customLineActive?: boolean
    sprinklesCss?: Sprinkles
    withLine?: boolean
    materialPanels?: TPanelMaterials
  }

function InlineCtaEntity(
  {
    className,
    weight,
    transform,
    fontFamily,
    type,
    htmlType,
    theme,
    children,
    sprinklesCss,
    customLineActive = false,
    withLine = false,
    materialPanels,
    onMouseEnter,
    onMouseLeave,
    ...props
  }: InlineCtaProps,
  ref: Ref<HTMLButtonElement | HTMLAnchorElement>
) {
  const [displayLine, setDisplayLine] = useState(false)

  const { add } = usePanel()

  function triggerPopin() {
    if (!materialPanels) return null
    add(<PanelMaterialsComponent materialsItems={materialPanels} />)
  }

  function toggleDisplayLine() {
    if (!withLine) {
      return
    }
    setDisplayLine((prev) => !prev)
  }

  return (
    <Link
      ref={ref}
      className={clsx(
        className,
        sprinkles({ cursor: "pointer", display: "inline-flex", ...(sprinklesCss ?? {}) }),
        css.InlineCta({ weight, theme, transform, type, fontFamily })
      )}
      {...props}
      title={props.title || (typeof children === "string" ? children : undefined)}
      onMouseEnter={(event) => {
        onMouseEnter?.(event)
        toggleDisplayLine()
      }}
      onMouseLeave={(event) => {
        onMouseLeave?.(event)
        toggleDisplayLine()
      }}
      onClick={materialPanels ? triggerPopin : props?.onClick}
      htmlType={htmlType}
      data-comp="UI/InlineCta"
    >
      {children}
      <div className={css.LineBlock}>
        <AnimatePresence initial={false}>
          {(customLineActive || displayLine) && (
            <m.div
              initial={{
                x: "-100%",
              }}
              animate={{
                x: 0,
                transition: {
                  duration: 0.25,
                  ease: fromBezierToArray(easings["easeOutQuad"]),
                },
              }}
              exit={{
                x: "100%",
                transition: { duration: 0.25, ease: fromBezierToArray(easings["easeInQuad"]) },
              }}
              className={css.Line}
            />
          )}
        </AnimatePresence>
      </div>
    </Link>
  )
}

export default forwardRef(InlineCtaEntity)
